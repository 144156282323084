<template>
  <div id="homepage">
    <!-- ======= Header ======= -->
    <header
      id="header"
      class="fixed-top d-flex align-items-center header-transparent"
    >
      <site-header />
    </header>
    <!-- End Header -->

    <!-- ======= Hero Section ======= -->
    <Carousel
      :titlePage="'Danh sách tin tức' "
    />
    <!-- End Hero -->

    <div class="container news-detail-page">
      <div class="d-flex">
        <feather-icon
          icon="HomeIcon"
          size="20"
          color="#0282CD"
        />
        <feather-icon
          icon="ChevronRightIcon"
          size="20"
          class="ml-1"
        />
        <div class="titlePage">
          Tin tức
        </div>
      </div>
      <section>
        <div class="row">
          <b-card
            :img-src="newsInfo.avatar ? ($serverfile + newsInfo.avatar) : imgDefault"
            class="news-detail col-md-8 col-lg-8   mr-1 ml-1"
          >
            <h3 class="title mt-1">
              {{ newsInfo.name }}
            </h3>
            <div class="d-flex  mb-1">
              <div>
                <span>
                  <feather-icon
                    icon="UserIcon"
                    class="news-icon"
                  />
                  {{ newsInfo.createdby }}
                </span>
              </div>
              <div class="ml-1">
                <span>
                  <feather-icon
                    icon="CalendarIcon"
                    class="news-icon"
                  />
                  {{ newsInfo.createdDate | formatDateToDDMM }}
                </span>
              </div>
            </div>
            <div
              class="news-content"
              v-html="newsInfo.content"
            >
            </div>
          </b-card>

          <div
            class="col-md-3 col-lg-3  news-top-container"
          >
            <!-- <b-form-input
              class="search-new-input"
              placeholder="Tìm kiếm"
            /> -->
            <!-- <div class="table-tree-news boxR p-2 mt-2">
              <h4>
                Chủ đề
              </h4>
              <liqui-tree
                class="tree-mini"
                :data="items"
                :options="options"
              >
              </liqui-tree>
              <b-button
                class="button-tree mt-2  animate__animated animate__fadeInDown"
                variant="primary"
              >
                Xóa tất cả bộ lọc
              </b-button>
            </div> -->

            <div class="p-1">
              <h3>
                Tin gần đây
              </h3>
              <div
                v-for="item in dataTopNewsList"
                :key="item.id"
                class="row mt-2 news-top"
              >
                <a
                  class="col-4 box-img-new"
                  :href="'/chi-tiet-tin-tuc/' + item.id"
                >
                  <img
                    class="news-top-img"
                    :src="item.avatar ? ($serverfile + item.avatar) : imgDefaultMenu"
                  >
                </a>
                <a
                  class="col-8 news-top-title"
                  :href="'/chi-tiet-tin-tuc/' + item.id"
                >
                  <h5 class="text-truncate text-color">{{ item.name }}</h5>
                  <span class="text-truncate text-color">{{ item.createdby }}</span>
                </a>
              </div>
            </div>

          </div>

        </div>
      </section>
    </div>
    <!-- End #main -->

    <!-- ======= Footer ======= -->
    <div
      id="footer"
    >
      <site-footer />
    <!-- End Footer -->

    <!-- <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
    ><i class="bi bi-arrow-up-short"></i></a> -->
    </div>
  </div>
</template>
<script src="./assets/js/main.js"></script>
<script>
import "./assets/vendor/aos/aos.js";
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'
import ConstantsApi from './constants/ConstantsApi'
import Carousel from './components/Carousel.vue'
import LiquiTree from 'liquor-tree'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import store from './store'
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BCard,
  BInputGroup,
  BPagination,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BDropdown,
  BButton,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  components: {
  BCard,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BPagination,
    SiteHeader,
    SiteFooter,
    Carousel,
    LiquiTree,
    BButton,
  },
    filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      imgDefault: store.imgDefaultSquare,
      imgDefaultMenu: store.imgDefault,
      tokenCheck: localStorage.getItem("token"),
      currentPage: 1,
      perPage: 1,
      rows: 200,
      newsInfo: {},
      dataTopNewsList: [],
      items: [
            {text: 'Item 1'},
            {text: 'Item 2'},
            {text: 'Item 3', children: [
              {text: 'Item 3.1'},
              {text: 'Item 3.2'}
            ]}
      ],
      options: {
        checkbox: true,
        propertyNames: {
          text: 'text',
          children: 'children',
          value: 'value',
        },
      },
    };
  },
  mounted() {
    /* eslint-disable no-undef */
    /* eslint-disable no-new */
    // eslint-disable-next-line func-names
    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      }
      return document.querySelector(el);
    };

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      const selectEl = select(el, all);
      if (selectEl) {
        if (all) {
          selectEl.forEach((e) => e.addEventListener(type, listener));
        } else {
          selectEl.addEventListener(type, listener);
        }
      }
    };

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
      el.addEventListener("scroll", listener);
    };

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
      const header = select("#header");
      let offset = header.offsetHeight;

      if (!header.classList.contains("header-scrolled")) {
        offset -= 20;
      }

      const elementPos = select(el).offsetTop;
      window.scrollTo({
        top: elementPos - offset,
        behavior: "smooth",
      });
    };

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    const selectHeader = select("#header");
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add("header-scrolled");
        } else {
          selectHeader.classList.remove("header-scrolled");
        }
      };
      window.addEventListener("load", headerScrolled);
      onscroll(document, headerScrolled);
    }

    /**
     * Back to top button
     */
    const backtotop = select(".back-to-top");
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add("active");
        } else {
          backtotop.classList.remove("active");
        }
      };
      window.addEventListener("load", toggleBacktotop);
      onscroll(document, toggleBacktotop);
    }

    /**
     * Mobile nav toggle
     */
    // eslint-disable-next-line func-names
    on("click", ".mobile-nav-toggle", function (e) {
      select("#navbar").classList.toggle("navbar-mobile");
      this.classList.toggle("bi-list");
      this.classList.toggle("bi-x");
    });

    /**
     * Mobile nav dropdowns activate
     */
    // eslint-disable-next-line func-names
    on(
      "click",
      ".navbar .dropdown > a",
      function (e) {
        if (select("#navbar").classList.contains("navbar-mobile")) {
          e.preventDefault();
          this.nextElementSibling.classList.toggle("dropdown-active");
        }
      },
      true
    );

    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    // eslint-disable-next-line func-names
    on(
      "click",
      ".scrollto",
      function (e) {
        if (select(this.hash)) {
          e.preventDefault();

          const navbar = select("#navbar");
          if (navbar.classList.contains("navbar-mobile")) {
            navbar.classList.remove("navbar-mobile");
            const navbarToggle = select(".mobile-nav-toggle");
            navbarToggle.classList.toggle("bi-list");
            navbarToggle.classList.toggle("bi-x");
          }
          scrollto(this.hash);
        }
      },
      true
    );

    /**
     * Skills animation
     */
    const skilsContent = select(".skills-content");
    if (skilsContent) {
      // eslint-disable-next-line no-new
      new Waypoint({
        element: skilsContent,
        offset: "80%",
        handler(direction) {
          const progress = select(".progress .progress-bar", true);
          progress.forEach((el) => {
            el.style.width = `${el.getAttribute("aria-valuenow")}%`;
          });
        },
      });
    }

    /**
     * Porfolio isotope and filter
     */
    window.addEventListener("load", () => {
      const portfolioContainer = select(".portfolio-container");
      if (portfolioContainer) {
        // eslint-disable-next-line no-undef
        const portfolioIsotope = new Isotope(portfolioContainer, {
          itemSelector: ".portfolio-wrap",
          layoutMode: "fitRows",
        });

        const portfolioFilters = select("#portfolio-flters li", true);

        // eslint-disable-next-line func-names
        on(
          "click",
          "#portfolio-flters li",
          function (e) {
            e.preventDefault();
            portfolioFilters.forEach((el) => {
              el.classList.remove("filter-active");
            });
            this.classList.add("filter-active");

            portfolioIsotope.arrange({
              filter: this.getAttribute("data-filter"),
            });
            portfolioIsotope.on("arrangeComplete", () => {
              // eslint-disable-next-line no-undef
              AOS.refresh();
            });
          },
          true
        );
      }
    });
  },
  created() {
    if(this.$route.params?.id) {
      this.fetchNewsData(this.$route.params.id)
      this.fetchTopNewsData()
    }else {
      this.$router.push({name: 'error-404'})
    }
  },
  methods: {
    async fetchNewsData(id) {
      const { data } = await axiosApiInstance.get(ConstantsApi.GET_NEWS_BY_ID  + id)
      this.newsInfo = data
    },
    async fetchTopNewsData() {
      const { data } = await axiosApiInstance.get(ConstantsApi.NEWS_GET_PAGING, {
        params: { pageSize: 5, pageNumber: 1 },
      })
      this.dataTopNewsList = data?.data?.pageLists
    },
     gotoDetail(id) {
      this.$router.push({ name: "news-detail-home",params: {id} });
    },
  },
};
</script>
<style lang="scss">

.news-detail-page {
  .card{
    box-shadow: 0 0 0 0 rgb(34 41 47 / 10%);
  }
  .card-body{
    padding: 0;
    background: #F8F8F8;
  }
  padding: 60px 0;
  img{
    width: 100%;
  }
  .card-img{
    border-radius: 0;
  }
  .col-lg-8.col-md-8{
    padding: 0;
  }
.news-detail {

  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    color: #0282CD;
  }
  .news-icon {
    color: #FFB03B;
  }
  .news-content {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
    box-sizing: content-box !important;
  }
}
.news-top-container {
  height: fit-content;
  background: #f8f8f8;
  .news-top {
      .news-top-img {
        width: 80px;
        height: 80px;
        object-fit: cover;
      }
      .news-top-title {
        height: 10px;
      }
    }
    .tree-checkbox {
      border: 1px solid #a6a8b0;
      width: 18px;
      height: 18px;
    }
    .tree-checkbox.checked:after{
      top:0;
      left: 6px;
      height: 10px;
      width: 3px;
    }
    .button-tree{
      width: 100%;
    }
    .text-color{
      color: #2E3A4A;
    }
  }
}
@media (max-width: 1220px) {
  .news-detail-page {
    .news-detail {
        flex-wrap: wrap-reverse;
        width: 100% !important;
        flex: auto ;
        max-width: 100% ;

    }
      .news-top-container {
        padding-left: 0 !important;
        margin-bottom: 1.5rem;
        width: 70%;
        flex: auto !important;
        max-width: 100% !important;
        .news-top {
          width: 50%;
        }
      }
      .box-img-new {
        padding-left: 0 !important;
        flex: 0 0 1%;
      }
  }
}
</style>
